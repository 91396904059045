// Write your custom css/scss here



.product_price{
     font-size: 13px;
     font-weight: bold;
     color: #6610f2;
}
.promo_price{
    color: red;
    text-decoration: line-through;
}